<template>
  <div
    class="px-2 option-container d-flex align-center"
    :class="{'w-full':$vuetify.breakpoint.xs}"
  >
    <v-menu
      bottom
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          outlined
          color="primary"
          v-bind="attrs"
          class="px-2 camel-case option-btn"

          v-on="on"
        >
          <v-icon left>
            mdi-file-download-outline
          </v-icon>
          Export
        </v-btn>
      </template>
      <v-list>
        <v-list-item @click="downloadLeads">
          <v-list-item-title>Alle Kontakte (.CSV)</v-list-item-title>
        </v-list-item>
        <v-list-item @click="downloadLeads({onlyNotExported :true})">
          <v-list-item-title>Noch nicht exportierte (.CSV)</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { exportLeads } from '@/lib/export'

export default {
  props: {
    onExported: { type: Function, default: () => {} },
    sortBy: { type: String, default: null },
    sortDesc: { type: Boolean, default: true },
    search: { type: String, default: '' },
    filters: { type: Object, default: () => {} }
  },
  methods: {
    async downloadLeads ({ onlyNotExported = false }) {
      await exportLeads({
        sortBy: this.sortBy,
        sortDesc: this.sortDesc,
        search: this.search,
        filters: JSON.stringify(this.filters),
        shouldExport: true,
        onlyNotExported
      })
      this.onExported()
    }
  }
}
</script>
